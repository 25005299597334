import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Typography } from "@material-ui/core/index"

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Typography variant={"h3"}>Hi from the second page</Typography>
    <Typography>Welcome to page 2</Typography>
    <Link to="/">
      <Typography>Go back to the homepage</Typography>
    </Link>
  </Layout>
)

export default SecondPage
